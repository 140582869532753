import React from 'react'

import { TailSpin, TailSpinProps } from 'react-loader-spinner'

import { colors } from 'settings/theme'

interface LoaderProps extends Partial<TailSpinProps> {
  size?: number | string
  wrapperClass?: string
}

const Loader: React.FC<LoaderProps> = ({ size, wrapperClass, ...props }) => {
    return (
        <TailSpin
            ariaLabel="loading-indicator"
            color={colors.primary}
            width={size}
            wrapperClass={wrapperClass}
            {...props}
        />
    )
}

export default Loader
